//async function loginUser(credentials) {
//    return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Authenticate", {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json'
//        },
//        body: JSON.stringify(credentials)
//    })
//        .then(data => data.json())
//}

function qparaNullCheck(para) {
    if (para == null) {
        return "";
    }
    else {
        return para;
    }

}

const geoNow = {
    api: {
        user: {
            loginUser: async (credentials, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Authenticate", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(credentials)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            authToken: async (token, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/AuthToken", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ token: token })
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getUserMe: async (token, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Me", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            add: async (token, newUser, ignorePasswordPolicy = false, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Add?ignorePasswordPolicy=" + ignorePasswordPolicy, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newUser)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            }
            ,
            update: async (token, userId, user, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/" + userId, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(user)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            listAll: async (token, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            },
        },
        project: {
            /**
             *
             * @param {string} token JWT Token
             * @param {string} uid User Id
             */
            getJoinedProjects: async (token, uid, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/UsersInProjects/ListProjects/" + uid + "?verbose=true", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            update: async (token, projectId, project, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Projects/" + projectId, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(project)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getById: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Projects/" + projectId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            add: async (token, newProject, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Projects/Add", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(newProject)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                    })
            },

            listAll: async (token, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Projects", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                    });
            },
            getDescriptionImageUrl: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Projects/" + projectId + "/GetDescriptionImageStream", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                })
                    .then(response => response.blob())
                    .then(blob => {
                        // read blob to base64 image dataUrl

                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        });
                    })
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                    });
            },
            getUri_uploadAndSetDescriptionImage: (projectId) => process.env.REACT_APP_WEBAPI_HOST + "/Projects/" + projectId + "/UploadAndSetDescriptionImage",
        },
        userInProject: {
            smartPatch: async (token, arrayUserInProjects, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/UsersInProjects/SmartPatch", {
                    method: 'PATCH',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(arrayUserInProjects)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                    });
            }
        },
        instrumentExtends: {
            listByProjectId: async (token, projectId, depthSeriesConfigId = null, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/InstrumentExtends/Projects/" + projectId + "?depthSeriesConfigId=" + qparaNullCheck(depthSeriesConfigId), {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            importToProject: async (token, projectId, instrumentExtends, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/InstrumentExtends/Projects/" + projectId + "/Import/Data", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(instrumentExtends)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token
             * @param {string} projectId
             * @param {Object[]} instrumentExtends
             * @param {Function} errorHandler
             */
            add: (token, instrumentExtends, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/InstrumentExtends/Add", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(instrumentExtends)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token
             * @param {string[]} ids
             * @param {Function} errorHandler
             */
            batchDelete: (token, ids, errorHandler = null) => {
                let concat = ids.join(",");
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/InstrumentExtends/BatchDelete/" + concat, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            batchUpdate: (token, addItems, updateItems, errorHandler = null) => {
                let oBody = {
                    add: addItems,
                    update: updateItems
                };
                let jsonBody = JSON.stringify(oBody);
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/InstrumentExtends/BatchUpdate", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: jsonBody
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token
             * @param {object} basicItem
             * @param {number[]} pointIndices
             * @param {boolean} isTwoPointsGoStraight
             * @param {Function} errorHandler
             */
            addWithBatchPointIndices: (token, basicItem, pointIndices, isTwoPointsGoStraight = false, errorHandler = null) => {
                let oBody = basicItem;
                if (oBody.depthSeriesConfigId === '') {
                    oBody.depthSeriesConfigId = null;
                }
                let jsonBody = JSON.stringify(oBody);
                let concatPointIndices = pointIndices.join(",");

                //Validation
                /**@type Error */
                let err = null;
                if (isTwoPointsGoStraight) {
                    if (pointIndices.length !== 2) {
                        err = new Error("Exact two points are required if isTwoPointsGoStraight is activated.");
                    }
                    if (pointIndices[0] >= pointIndices[1]) {
                        err = new Error("The first point index must be smaller than the second one.");
                    }
                }

                if (err != null) {
                    if (errorHandler != null) {
                        errorHandler({
                            message: err.message,
                            stack: err.stack
                        });
                        return;
                    }
                    throw err;
                }

                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/InstrumentExtends/BatchAddWithPointIndices/" + concatPointIndices + "?twoPointsGoStraight=" + isTwoPointsGoStraight, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: jsonBody
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },

        },
        projectKmls: {
            listByProjectId: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/ProjectKmls/" + projectId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            create: async (token, kml, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/ProjectKmls/create", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(kml)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            },
            update: async (token, id, kml, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/ProjectKmls/kml/" + id, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(kml)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            },
            delete: async (token, id, keepFile = false, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/ProjectKmls/kml/" + id + "?keepFile=" + keepFile, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            },
            deleteAll: async (token, projectId, keepFile = false, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/ProjectKmls/" + projectId + "/All?keepFile=" + keepFile, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            }
            ,
            upload: async (token, projectId, file, options = {}, errorHandler = null) => {
                const queryString = new URLSearchParams({
                    customFilename: options.customFilename ?? "",
                    useUploadFilename: options.useUploadFilename ?? true,
                    autoAddItem: options.autoAddItem ?? false,
                    replaceFirstMode: options.replaceFirstMode ?? false,
                    lineWidthMultiplier: options.lineWidthMultiplier ?? 1
                }).toString();
                const formData = new FormData();
                formData.append("file", file);

                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/ProjectKmls/upload/" + projectId + "?" + queryString, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: formData
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            }
        },
        depthSeriesConfigs: {
            listByProjectId: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/DepthSeriesConfigs/Projects/" + projectId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            importToProject: async (token, projectId, depthSeriesConfigs, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/DepthSeriesConfigs/Projects/" + projectId + "/Import/Data", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(depthSeriesConfigs)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token
             * @param {Array<Object>} depthSeriesConfigs
             * @param {function} errorHandler
             */
            add: async (token, depthSeriesConfigs, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/DepthSeriesConfigs/Add", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(depthSeriesConfigs)
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            },
            /**
             * 
             * @param {string} token
             * @param {string[]} ids
             * @param {function} errorHandler
             */
            delete: async (token, ids, errorHandler = null) => {
                let bodyPayload = JSON.stringify({
                    targetsId: ids
                });
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/DepthSeriesConfigs/BatchDelete", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: bodyPayload
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            },
            batchUpdate: (token, addDscItems, updateDscItems, errorHandler = null) => {
                let oBody = {
                    add: addDscItems,
                    update: updateDscItems
                };
                let jsonBody = JSON.stringify(oBody);
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/DepthSeriesConfigs/BatchUpdate", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    },
                    body: jsonBody
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    }
                    );
            }
        },
        siteData: {
            getParabase: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/SiteData/Projects/" + projectId + "/Parabase/", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token
             * @param {string} projectId
             * @param {Array<number>} pointIndice
             * @param {string} dtLeft
             * @param {string} dtRight
             * @param {number?} nth
             */
            getTimeHistory: async (token, projectId, pointIndice, dtLeft, dtRight, nth = null, errorHandler = null) => {
                let route = "/SiteData/Projects/" + projectId + "/TimeHistory/" + pointIndice.join(",") +
                    "?chartJS=true&datatable=true" + "&dtLeft=" + dtLeft + "&dtRight=" + dtRight;
                if (nth !== null) {
                    route += "&nth=" + nth;
                }
                return fetch(process.env.REACT_APP_WEBAPI_HOST + route, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token
             * @param {string} projectId
             */
            getLatestDataWithAlert: async (token, projectId, lang = 'zh-tw', errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/SiteData/Projects/" + projectId + "/LatestDataWithAlert?lang=" + lang, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token JWT Token
             * @param {string[]} projectId ±M®×GUID
             * @param {boolean} onlyInParabase ­­©w¥u¦C¥X¦³¥X²{¦b Parabase ªº Gages
             * @param {string} lang ¼Ð·Ç¤å¤Æ½X
             */
            getGageDefinition: async (token, projectId, onlyInParabase, lang = 'zh-tw', errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId.join(",") + "/GageDefinition?onlyInParabase=" + onlyInParabase + "&lang=" + lang, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {string} token JWT Token
             * @param {string} projectId ±M®×GUID
             */
            getAreas: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/SiteData/Projects/" + projectId + " /Areas", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            listDscParaBaseExItemsByProjectId: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/ListDscParaBaseExItems", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            listAvailableTimeListDataByProjectId: async (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/ListAvailableTimeListData", {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {any} token
             * @param {any} projectId
             * @param {any} depthSeriesId
             * @param {number[]} meaNo
             * @param {any} errorHandler
             */
            getDepthSerieMeaNoValueEntries: async (token, projectId, depthSeriesId, meaNo, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/DepthSeries/" + depthSeriesId + "/" + meaNo.join(","), {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            /**
             * 
             * @param {any} token
             * @param {any} projectId
             * @param {any} depthSeriesId
             * @param {number[]} meaNo
             * @param {any} errorHandler
             */
            getDepthSeriesDownloadStandardExcel: async (token, projectId, depthSeriesId, meaNo, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/DepthSeries/" + depthSeriesId + "/DownloadStandardExcel", {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json' //POST 少了這個會 415 錯誤
                    },
                    body: JSON.stringify({
                        "MeaNo": meaNo
                    })
                })
                    .then(response => response.blob())
                    .then(response => {
                        const blob = new Blob([response], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        const downloadUrl = URL.createObjectURL(blob);

                        const a = document.createElement("a");
                        a.href = downloadUrl;
                        a.download = "DepthSeriesData.xlsx";
                        document.body.appendChild(a);
                        a.click();
                    })
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getListEQEntries: async (token, projectId, page, pageSize, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/ListEQ/Data?page=" + page + "&size=" + pageSize, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getListEQFile: async (token, projectId, filepath, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    getUri_getListEQFile(projectId, filepath), {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getListEQ_XYZGraph: (token, projectId, isV, filepath, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/ListEQ/Download/XYZGraph?isV=" + isV + "&filepath=" + encodeURI(filepath), {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getUri_getListEQFile: (projectId, isV, filepath, axis = "x", token = null) => {
                let qToken = "";
                if (token !== null) {
                    qToken = "&token=" + token;
                }
                return process.env.REACT_APP_WEBAPI_HOST +
                    "/SiteData/Projects/" + projectId + "/ListEQ/Download?isV=" + isV + "&axis=" + axis + "&filepath=" + encodeURI(filepath) + qToken;
            },
            getUri_getListEQEntries: (projectIds) => process.env.REACT_APP_WEBAPI_HOST + "/SiteData/Projects/" + projectIds.join(",") + "/ListEQ/Data",
            getUri_getStatusTable: (projectIds) => process.env.REACT_APP_WEBAPI_HOST + "/SiteData/Projects/" + projectIds.join(",") + "/RealtimeStatusTable/Data",
        },
        cwb: {
            getStoredEQItems: (token, page = 1, size = -1, filter = null, errorHandler = null) => {
                return fetch(geoNow.api.cwb.getUri_getStoredEQItems(false, page, size, filter), {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            getUri_getStoredEQItems: (noPara = false, page = 1, size = -1, filter = null) => process.env.REACT_APP_WEBAPI_HOST + "/Cwb/EarthQuakes/StoredEQ/Data" + (noPara ? "" : `?page=${page}+&size=${size}&filter=${filter ?? ""}`),
        },
        homeCarousels: {
            getListByProjectId: (token, projectId, errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/HomeCarousels/Projects/" + projectId, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            },
            downloadMedia: (token, id) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST +
                    "/HomeCarousels/DownloadMedia?id=" + id, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                    .then(data => data.blob())
                    .then(blob => {
                        return blob;
                    })
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    })
            }
        },
        siteInfo: {
            getPublicSiteInfo: async (errorHandler = null) => {
                return fetch(process.env.REACT_APP_WEBAPI_HOST + "/SiteInfo/public", {
                    method: 'GET'
                })
                    .then(data => data.json())
                    .catch(err => {
                        if (errorHandler != null) {
                            errorHandler({
                                message: err.message,
                                stack: err.stack
                            });
                        }
                        console.error(err);
                    });
            }
        },
        signalR: {
            getUri_cwbeq: function () { return process.env.REACT_APP_WEBAPI_HOST + "/cwbeq" }
        }
    },
    stateHelper: {
        project: {
            /**
             * 
             * @param {Array.<Object>} uipList
             */
            getDefaultProjectIdFromUipList: (uipList) => {
                for (let i = 0; i < uipList.length; i++) {
                    let uip = uipList[i];
                    if (uip.isProjectDefault) {
                        return uip.projectId;
                    }
                }
                return null;
            },
            /**
             *
             * @param {Array.<Object>} uipList
             */
            getDefaultProjectItemFromUipList: (uipList) => {
                for (let i = 0; i < uipList.length; i++) {
                    let uip = uipList[i];
                    if (uip.isProjectDefault) {
                        return uip;
                    }
                }
                return null;
            },
            /**
             *@param {string} id Project Id
             * @param {Array.<Object>} uipList
             */
            getJoinedProjectItemByProjectId: (id, uipList) => {
                for (let i = 0; i < uipList.length; i++) {
                    let uip = uipList[i];
                    // lower case comparison
                    if (uip.projectId.toLowerCase() === id.toLowerCase()) {
                        return uip;
                    }
                }
            },
            customDataField: {
                getDefaultOrderList: () => {
                    return [
                        { key: geoNow.stateHelper.project.customDataField.keys.light, isChecked: true, title: '燈號' },
                        { key: geoNow.stateHelper.project.customDataField.keys.pointIdx, isChecked: true, title: '編號' },
                        { key: geoNow.stateHelper.project.customDataField.keys.pointNo, isChecked: true, title: '儀器名稱' },
                        { key: geoNow.stateHelper.project.customDataField.keys.value, isChecked: true, title: '物理量' },
                        { key: geoNow.stateHelper.project.customDataField.keys.initial, isChecked: true, title: '初值' },
                        { key: geoNow.stateHelper.project.customDataField.keys.alert, isChecked: true, title: '警戒值' },
                        { key: geoNow.stateHelper.project.customDataField.keys.alarm1, isChecked: true, title: '警示1' },
                        { key: geoNow.stateHelper.project.customDataField.keys.alarm2, isChecked: true, title: '警示2' },
                        { key: geoNow.stateHelper.project.customDataField.keys.action, isChecked: true, title: '行動值' },
                        { key: geoNow.stateHelper.project.customDataField.keys.note, isChecked: true, title: '備註' },
                    ];
                },
                keys: {
                    pointIdx: '#',
                    pointNo: 'pointNo',
                    value: 'value',
                    initial: 'initial',
                    alert: 'alert',
                    alarm1: 'alarm1',
                    alarm2: 'alarm2',
                    action: 'action',
                    note: 'note',
                    light: '@'
                }
            }
        }
    },
    setToken: (t) => {
        if (t === null) {
            localStorage.removeItem("token");
            return;
        }
        localStorage.setItem("token", t);
    },
    getToken: (t) => {
        return localStorage.getItem("token");
    }
};

export default geoNow;