
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin3.svg";
import GeotechSVG from "../../assets/img/illustrations/geotechscience.svg";
import GeoNowLogo from "../../assets/img/brand/geonow/GeoNow_WithText_500w.png"

import swal from 'sweetalert';
import { useSelector, useDispatch } from 'react-redux';
import { doSignIn, doSignOut, doSetJoinedProjects, doSetCurrentProjectId } from './../../actions';
import { VERSION, BUILD_DATE } from '../../version';
import geoNow from '../../utils/geonow';
/*import { UserContext } from './contexts/UserContext'*/


//async function loginUser(credentials) {
//    return fetch(process.env.REACT_APP_WEBAPI_HOST + "/Users/Authenticate", {
//        method: 'POST',
//        headers: {
//            'Content-Type': 'application/json'
//        },
//        body: JSON.stringify(credentials)
//    })
//        .then(data => data.json())
//}

export default () => {

    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [webapiVersionInfo, setWebapiVersionInfo] = useState({ version: '', buildDate: '', timeZone: '' });

    const signin = (user) => dispatch(doSignIn(user));

    useEffect(() => {
        const fetchVersionInfo = async () => {
            try {
                const response = await geoNow.api.siteInfo.getPublicSiteInfo();
                if (response) {
                    const { ver, now, tz } = response;
                    setWebapiVersionInfo({
                        version: ver,
                        buildDate: now,
                        timeZone: tz
                    });
                }
            } catch (error) {
                console.error('Error fetching version info:', error);
            }
        };

        fetchVersionInfo();
    }, []);


    const handleSubmit = async e => {
        e.preventDefault();
        console.log("Signin SUBMIT! :O");
        setIsLoggingIn(true);
        const res_auth = await geoNow.api.user.loginUser({
            username,
            password
        });
        setIsLoggingIn(false);

        if (('token' in res_auth) === false) {
            swal("Failed", res_auth.message, "error");
        }
        else {
            signin(res_auth);
            geoNow.setToken(res_auth.token);

            const res_uip_list = await geoNow.api.project.getJoinedProjects(res_auth.token, res_auth.id);
            if (res_uip_list && res_uip_list.status && res_uip_list.status === 1) {
                dispatch(doSetJoinedProjects(res_uip_list.message));
                let defaultPid = geoNow.stateHelper.project.getDefaultProjectIdFromUipList(res_uip_list.message);
                dispatch(doSetCurrentProjectId(defaultPid));
            }

            swal("Success", "登入成功!", "success", {
                buttons: false,
                timer: 2000,
            })
                .then((value) => {
                    //localStorage.setItem('u_token', response['token']);
                    //localStorage.setItem('u_id', response['id']);
                    //localStorage.setItem('u_username', response['username']);
                    //localStorage.setItem('u_firstname', response['firstName']);
                    //localStorage.setItem('u_lastname', response['lastName']);
                    //UserContext.useLocalStorage('user', response);

                    // localStorage.setItem('user', JSON.stringify(response['user']));


                    window.location.href = "#" + Routes.DashboardOverview.path;
                });
        }
    }

    return (
        <main>
            {/*<UserContext.Provider value={this.state}>*/}
            {/*</UserContext.Provider>*/}
            <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
                <Container>
                    <p className="text-center">
                        <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700">
                            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> 回到首頁
                        </Card.Link>
                    </p>
                    <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
                        <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div id="loginPanel" className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500" >
                                <div className="text-center text-md-center mb-4 mt-md-0">
                                    {/*<div style={{ minHeight: 60, maxHeight: 90, backgroundImage: `url(${GeoNowSVG})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}> </div>*/}
                                    <img src={GeoNowLogo} />
                                    
                                    {/*<h4 className="mb-0"><div style={{ minHeight: 50, maxHeight: 90, backgroundImage: `url(${GeotechSVG})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', display: 'inline-block', minWidth: 50 }}></div>捷儀科技</h4>*/}
                                    
                                    
                                </div>
                                <Form className="mt-4" onSubmit={handleSubmit}>
                                    <Form.Group id="email" className="mb-4">
                                        <Form.Label>信箱</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </InputGroup.Text>
                                            <Form.Control autoFocus required type="email" placeholder="example@gmail.com" onChange={e => setUserName(e.target.value)} />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Group id="password" className="mb-4">
                                            <Form.Label>密碼</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faUnlockAlt} />
                                                </InputGroup.Text>
                                                <Form.Control required type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                                            </InputGroup>
                                        </Form.Group>
                                        {false && (
                                            <div className="d-flex justify-content-between align-items-center mb-4">
                                                {/*<Form.Check type="checkbox">*/}
                                                {/*    <FormCheck.Input id="defaultCheck5" className="me-2" />*/}
                                                {/*    <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">記住我</FormCheck.Label>*/}
                                                {/*</Form.Check>*/}
                                                <Card.Link className="small text-end">忘記密碼?</Card.Link>
                                            </div>
                                        )}
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100" disabled={isLoggingIn}>
                                        {!isLoggingIn ? "登入" : "登入中..."}
                                    </Button>
                                    <div className="text-center small text-muted" style={{
                                        position: 'relative',
                                        top: '2rem',
                                    }}>
                                        <span style={{ color: '#c1bfcd' }}>{VERSION} / {webapiVersionInfo.version}</span>
                                        {/*<ul className="list-unstyled ">*/}
                                        {/*    <li className="mt-1 mb-1">*/}
                                        {/*        <span className="fw-normal">前端版本: {VERSION}</span>*/}
                                        {/*    </li>*/}
                                        {/*    <li className="mt-1 mb-1">*/}
                                        {/*        <span className="fw-normal">後端版本: {webapiVersionInfo.version}</span>*/}
                                        {/*    </li>*/}
                                        {/*</ul>*/}
                                    </div>
                                    {/*<div className="mt-3 mb-4 text-center">*/}
                                    {/*    <span className="fw-normal">前端版本: {VERSION}*/}{/* ({BUILD_DATE})*/}{/*</span>*/}
                                    {/*    */}{/*<div>*/}
                                    {/*    */}{/*    <p>Version: {versionInfo.version}</p>*/}
                                    {/*    */}{/*    <p>Build Date: {versionInfo.buildDate}</p>*/}
                                    {/*    */}{/*    <p>Time Zone: {versionInfo.timeZone}</p>*/}
                                    {/*    */}{/*</div>*/}
                                    {/*</div>*/}
                                    {/*<div className="mt-3 mb-4 text-center">*/}
                                    {/*    <span className="fw-normal">後端版本: {webapiVersionInfo.version}*/}{/* ({versionInfo.buildDate})*/}{/*</span>*/}
                                    {/*</div>*/}
                                </Form>

                                {/*<div className="mt-3 mb-4 text-center">*/}
                                {/*  <span className="fw-normal">or login with</span>*/}
                                {/*</div>*/}
                                {/*<div className="d-flex justify-content-center my-4">*/}
                                {/*  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">*/}
                                {/*    <FontAwesomeIcon icon={faFacebookF} />*/}
                                {/*  </Button>*/}
                                {/*  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">*/}
                                {/*    <FontAwesomeIcon icon={faTwitter} />*/}
                                {/*  </Button>*/}
                                {/*  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">*/}
                                {/*    <FontAwesomeIcon icon={faGithub} />*/}
                                {/*  </Button>*/}
                                {/*</div>*/}

                                {false && (
                                    <div className="d-flex justify-content-center align-items-center mt-4">
                                        <span className="fw-normal">
                                            還沒有帳號?
                                            <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                                                {` 建立一個 `}
                                            </Card.Link>
                                        </span>
                                    </div>
                                )}

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
};
