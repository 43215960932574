
import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import { useEffect } from "react";


export const UserInfoForm = ({isRegister, preData, submitHandler}) => {
    //const [birthday, setBirthday] = useState("");

    const [newPwd, setNewPwd] = useState("");
    const location = useLocation();
    //const pd = preData;
    //debugger;

    //async function handleSubmit(e) {
    //    e.preventDefault();
    //    // const { name, email, password, birthday,
    //}

    function ifStringEmptyThenNullify(string) {
        if (string === "") {
            return null;
        }
        return string;
    }

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <h5 className="mb-4">{ isRegister ? ("帳號註冊 (新增使用者)") : ("個人資料變更") }</h5>
                <Form onSubmit={e => {
                    e.preventDefault();
                    let submittedData = {
                        password: ifStringEmptyThenNullify(e.target.newPwd.value),
                        lastName: ifStringEmptyThenNullify(e.target.lastName.value),
                        firstName: ifStringEmptyThenNullify(e.target.firstName.value),
                        username: ifStringEmptyThenNullify(e.target.username.value),
                        isLastNameFirst: e.target.isLastNameFirst.checked
                    };
                    submitHandler(e, submittedData);
                }}>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group id="firstName">
                                <Form.Label>名字</Form.Label>
                                <Form.Control required={isRegister} name="firstName" type="text" placeholder={isRegister ? "例如: 捷儀" : preData?.firstName ?? ''}/>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group id="lastName">
                                <Form.Label>姓氏</Form.Label>
                                <Form.Control required={isRegister} name="lastName" type="text" placeholder={isRegister ? "例如: 黃" : preData?.lastName ?? ''} />
                            </Form.Group>
                        </Col>
                        <Col md={12} className="mb-3">
                            {/*CheckBox: isLastNameFirst*/}
                            <Form.Check type="checkbox" className="d-flex mb-4">
                                <Form.Check.Input id="cb_isLastNameFirst" name="isLastNameFirst" className="me-2"
                                    defaultChecked={isRegister ? false : preData?.isLastNameFirst}/>
                                <Form.Check.Label htmlFor="cb_isLastNameFirst">
                                    姓氏在前
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        {/*<Col md={6} className="mb-3">*/}
                        {/*  <Form.Group id="birthday">*/}
                        {/*    <Form.Label>Birthday</Form.Label>*/}
                        {/*    <Datetime*/}
                        {/*      timeFormat={false}*/}
                        {/*      onChange={setBirthday}*/}
                        {/*      renderInput={(props, openCalendar) => (*/}
                        {/*        <InputGroup>*/}
                        {/*          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>*/}
                        {/*          <Form.Control*/}
                        {/*            required*/}
                        {/*            type="text"*/}
                        {/*            value={birthday ? moment(birthday).format("MM/DD/YYYY") : ""}*/}
                        {/*            placeholder="mm/dd/yyyy"*/}
                        {/*            onFocus={openCalendar}*/}
                        {/*            onChange={() => { }} />*/}
                        {/*        </InputGroup>*/}
                        {/*      )} />*/}
                        {/*  </Form.Group>*/}
                        {/*</Col>*/}
                        {/*<Col md={6} className="mb-3">*/}
                        {/*  <Form.Group id="gender">*/}
                        {/*    <Form.Label>Gender</Form.Label>*/}
                        {/*    <Form.Select defaultValue="0">*/}
                        {/*      <option value="0">Gender</option>*/}
                        {/*      <option value="1">Female</option>*/}
                        {/*      <option value="2">Male</option>*/}
                        {/*    </Form.Select>*/}
                        {/*  </Form.Group>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <Col className="mb-3">
                            <Form.Group>
                                <Form.Label>帳號</Form.Label>
                                <Form.Control name="username" required={isRegister} type="email" placeholder={isRegister ? "account@gmail.com" : preData?.username ?? ''} />
                            </Form.Group>
                            <Form.Text muted>
                                帳號就是您的 Email。
                            </Form.Text>
                        </Col>
                        {/*<Col md={6} className="mb-3">*/}
                        {/*  <Form.Group id="phone">*/}
                        {/*    <Form.Label>Phone</Form.Label>*/}
                        {/*    <Form.Control required type="number" placeholder="+12-345 678 910" />*/}
                        {/*  </Form.Group>*/}
                        {/*</Col>*/}
                        
                    </Row>
                    <Row>
                        <Form.Group as={Col} className="mb-3" autoComplete="false">
                            <Form.Label>新密碼</Form.Label>
                            <Form.Control
                                required={isRegister}
                                onChange={e => setNewPwd(e.target.value)}
                                type="password" placeholder={!isRegister ? "若不變更請留空。" : ""}
                                name="newPwd"
                                />
                        </Form.Group>
                        <Form.Group as={Col} className="mb-3" style={{ visibility: newPwd === '' ? 'hidden' : 'visible' }}>
                            <Form.Label>新密碼確認</Form.Label>
                            <Form.Control required={isRegister || (newPwd !== '')} type="password" placeholder="請再輸入一次新設密碼"
                                />
                        </Form.Group>
                        {!isRegister && (
                            <Form.Text muted style={{ visibility: newPwd === '' ? 'hidden' : 'visible' }}>
                                若不想變更密碼，請清除新密碼欄位。
                            </Form.Text>
                        )}
                    </Row>
                    {/*<Row>*/}
                    {/*    {isRegister && (*/}
                    {/*        <>*/}
                    {/*            註冊模式*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*    {!isRegister && (*/}
                    {/*        <>*/}
                    {/*            編輯模式*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</Row>*/}
                    <div className="mt-3">
                        <Button variant="primary" type="submit">
                            {isRegister ? "註冊送出" : "更改送出"}
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};
