
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';


import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Alert } from '@themesberg/react-bootstrap';
/*import { SortableElement, SortableContainer } from 'react-sortable-hoc';*/
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

import { UploadySingleImagePreview }  from './UploadySingleImagePreview';

import geoNow from '../utils/geonow';

import Uploady from "@rpldy/uploady";
import UploadButton from '@rpldy/upload-button';



import moment from "moment-timezone";
import 'moment/locale/zh-tw';

export const ProjectInfoForm = ({ isCreation, isSuperUser, preData, submitHandler }) => {
    //const [birthday, setBirthday] = useState("");

    


    const location = useLocation();
    const refTb_ListEQ_DataFolder = React.useRef(null);

    const [, updateState] = React.useState();

    const [projDisplayKind, setProjDisplayKind] = useState(isCreation ? '0' : preData?.projectStatusDisplayKind ?? '0');
    useEffect(() => {
        if (preData && preData !== null) {
            setProjDisplayKind(preData?.projectStatusDisplayKind ?? '0');
            let preCustomDataFieldOrder = convertOrderStringToOrderList(preData?.projectCustomDataFieldOrder);
            setCustomFieldOrderList(preCustomDataFieldOrder);
        }
    }, [preData]);


    const forceUpdate = React.useCallback(() => updateState({}), []);

    const canEdit = isCreation ? true : (preData?.isProjectAdmin ?? false);

    //const uploadyContext = useUploady();

    const getMoveIndex = (array, dragItem) => {
        const { active, over } = dragItem;
        let activeIndex = 0;
        let overIndex = 0;
        try {
            // 遍历数组，查找出active和over的index
            array.forEach((item, index) => {
                if (active.id === item.key) {
                    activeIndex = index;
                }
                if (over.id === item.key) {
                    overIndex = index;
                }
            });
        } catch (error) {
            overIndex = activeIndex; // 如果有问题，则复位
        }
        return { activeIndex, overIndex };
    };
    function handleDataListChange(newDataList)
    {
        let newStr = convertOrderListToOrderString(newDataList);
        console.log(`[New CustomDataField Order]\n ${newStr}`)
        customFieldOrderString = newStr;
    }
    const defaultCustomFieldOrderList = geoNow.stateHelper.project.customDataField.getDefaultOrderList();
    //const defaultCustomFieldOrderList = [
    //    { key: '#', isChecked: true, title: '編號' },
    //    { key: 'pointNo', isChecked: true, title: '儀器名稱' },
    //    { key: 'value', isChecked: true, title: '物理量' },
    //    { key: 'initial', isChecked: true, title: '初值' },
    //    { key: 'alert', isChecked: true, title: '警戒值' },
    //    { key: 'alarm1', isChecked: true, title: '警示1' },
    //    { key: 'alarm2', isChecked: true, title: '警示2' },
    //    { key: 'action', isChecked: true, title: '行動值' },
    //    { key: 'note', isChecked: true, title: '備註' },
    //];
    const [customFieldOrderList, setCustomFieldOrderList] = useState(defaultCustomFieldOrderList);
    var customFieldOrderString = convertOrderListToOrderString(customFieldOrderList);
    function convertOrderListToOrderString(orderList) {
        // orderList: [{key: "point
        // No", isChecked: true}, ...]
        // if isChecked, then it is shown
        // if not, then it is not shown
        // if all items are shown, then return null or empty string
        // if some items are shown, then return the orderString
        // comment ends
        const orderArray = orderList.filter(item => item.isChecked).map(item => item.key);
        if (orderArray.length === 0) {
            return null;
        }
        // if order is the same as default and length are the same, then return null
        if (orderArray.length === defaultCustomFieldOrderList.length && orderArray.every((value, index) => value === defaultCustomFieldOrderList[index].key)) {
            return null;
        }
        return orderArray.join(",");
    }
    function convertOrderStringToOrderList(orderString) {
        // orderString: "pointNo,initial,alert,alarm1,alarm2,action,note"
        // if there are missing items, then it means that the missing items are not shown so checkbox should be unchecked
        // if orderString is null or empty, then it means that all items are shown (default)
        // respect the order of the orderString
        // comment stops
        if (orderString === null || orderString === "") {
            return defaultCustomFieldOrderList;
        }
        else {
            
            const orderArray = orderString.split(",");
            const newOrderList = defaultCustomFieldOrderList.map(item => {
                return {
                    ...item,
                    isChecked: orderArray.includes(item.key)
                };
            });

            // Sort the list based on the order in 'orderArray'
            newOrderList.sort((a, b) => {
                if (!a.isChecked && b.isChecked) {
                    return 1;
                }
                if (a.isChecked && !b.isChecked) {
                    return -1;
                }
                return orderArray.indexOf(a.key) - orderArray.indexOf(b.key);
            });

            return newOrderList;
            
        }


    }

    function DndSortComponent(props) {
        

        useEffect(() => {
            props.onChange(customFieldOrderList);// 这里写你自己对父组件传值的方式
        }, []);

        // 拖拽结束后的操作
        const dragEndEvent = (dragItem) => {
            setCustomFieldOrderList((prevDataList) => {
                const moveDataList = [...prevDataList];
                const { activeIndex, overIndex } = getMoveIndex(moveDataList, dragItem);
                const newDataList = arrayMove(moveDataList, activeIndex, overIndex);
                return newDataList;
            });
        };

        // 点击checkbox的操作，修改指定ID的对象的isChecked属性取反即可
        const handleCheckedChange = (chosedItemKey) => {
            setCustomFieldOrderList((prevDataList) => {
                const newDataList = [...prevDataList];
                const updatedDataList = newDataList.map((item) => {
                    if (item.key === chosedItemKey) {
                        return { ...item, isChecked: !item.isChecked };
                    }
                    return item;
                });
                return updatedDataList;
            });
        };

        // 拖拽项组件
        const SortableItem = (itemProps) => {
            // 父传子，从props里拿，建议使用其他名字（如itemProps）代替props，以免和父组件的props混淆
            const { checkboxItem } = itemProps;
            const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
                id: checkboxItem.key, // 这里传入的id属性必须和SortableContext的items数组一一对应
                transition: {
                    duration: 500,
                    easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
                },
            });
            const liStyles = {
                transform: CSS.Transform.toString(transform),
                transition,
                // disable li symbol
                listStyleType: 'none',
            };
            const textStyles = {
                cursor: 'move',
                userSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                minWidth: '100px',
                // align the text to the left
                textAlign: 'left',
            };
            return (
                <li ref={setNodeRef} {...attributes} style={liStyles}>
                    <label className="check-label">
                        <input
                            type="checkbox"
                            name={checkboxItem.key}
                            checked={checkboxItem.isChecked}
                            onChange={() => handleCheckedChange(checkboxItem.key)}
                        />
                        {/*<label className="name-label">{checkboxItem.ptitle || checkboxItem.title}</label>*/}
                        <label className="btn all-scroll" style={textStyles} {...listeners}>{checkboxItem.ptitle || checkboxItem.title}</label>
                        {/* 为Dom添加listeners属性表示设置其为可拖动项 */}
                        {/*<span className="btn all-scroll" {...listeners}>*/}
                        {/*    &#9836;*/}
                        {/*</span>*/}
                    </label>
                </li>
            );
        };

        return (
            <DndContext onDragEnd={dragEndEvent} modifiers={[restrictToParentElement]}>
                <SortableContext items={customFieldOrderList.map((c) => c.key)} strategy={verticalListSortingStrategy}>
                    {/* 这里的items接收一个数组，这个数组的值要和useSortable传入的id属性一一对应 */}
                    <div className="attrs">
                        <ul className="attrs-list">
                            {customFieldOrderList.map((checkboxItem) => (
                                <SortableItem checkboxItem={checkboxItem} key={checkboxItem.key} />
                            ))}
                        </ul>
                    </div>
                </SortableContext>
            </DndContext>
        );
    }


    function ifStringEmptyThenNullify(string) {
        if (string === "") {
            return null;
        }
        return string;
    }

    function ifSameValueThenNullify(newValue, oldValue) {
        if (newValue === oldValue) {
            return null;
        }
        return newValue;
    }

    function ifStringEmptyThenMagicFloat(string) {
        if (string === "") {
            // return C# float.MinValue
            return -3.40282347E+38;
        }
        return string;
    }

    function ifStringEmptyThenMagicInt(string) {
        if (string === "") {
            // return C# int.MinValue
            return -2147483648;
        }
        return string;
    }

    return (
        <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
                <h5 className="mb-4">
                    { isCreation ? ("新增專案"):("專案資料修改") }
                </h5>
                <Form onSubmit={e => {
                    e.preventDefault();
                    let v_mapPointTextOffsetX = ifStringEmptyThenMagicFloat(e.target.mapPointTextOffsetX.value);
                    let v_mapPointTextOffsetY = ifStringEmptyThenMagicFloat(e.target.mapPointTextOffsetY.value);
                    let v_lineNotifyCooldownMinutes = ifStringEmptyThenMagicInt(e.target.lineNotifyCooldownMinutes.value);

                    let submittedData = {
                        projectName: isCreation ? e.target.projectName.value : ifSameValueThenNullify(e.target.projectName.value, preData.projectName),
                        projectDescription: isCreation ? e.target.projectDescription.value : ifSameValueThenNullify(e.target.projectDescription.value, preData.projectDescription),
                        databaseName: isCreation ? e.target.databaseName.value : ifSameValueThenNullify(e.target.databaseName.value, preData.databaseName),
                        listEQ_Enabled: isCreation ? e.target.listEQ_Enabled.checked : ifSameValueThenNullify(e.target.listEQ_Enabled.checked, preData.projectListEQ_Enabled),
                        listEQ_DataFolder: isCreation ? refTb_ListEQ_DataFolder.current.value : ifSameValueThenNullify(refTb_ListEQ_DataFolder.current.value, preData.projectListEQ_DataFolder ?? ''),
                        statusTable_Enabled: isCreation ? e.target.statusTable_Enabled.checked : ifSameValueThenNullify(e.target.statusTable_Enabled.checked, preData.projectStatusTable_Enabled),
                        depthSeries_Title: isCreation ? e.target.depthSeries_Title.value : ifSameValueThenNullify(e.target.depthSeries_Title.value, preData.projectDepthSeries_Title),
                        statusDisplayKind: isCreation ? e.target.statusDisplayKind.value : ifSameValueThenNullify(e.target.statusDisplayKind.value, preData.projectStatusDisplayKind),
                        customDataFieldOrder: isCreation ? customFieldOrderString : ifSameValueThenNullify(customFieldOrderString, preData.projectCustomDataFieldOrder),
                        mapPointTextBorderColor: isCreation ? e.target.mapPointTextBorderColor.value : ifSameValueThenNullify(e.target.mapPointTextBorderColor.value, preData.projectMapPointTextBorderColor),
                        mapPointTextColor: isCreation ? e.target.mapPointTextColor.value : ifSameValueThenNullify(e.target.mapPointTextColor.value, preData.projectMapPointTextColor),
                        mapPointTextSize: isCreation ? e.target.mapPointTextSize.value : ifSameValueThenNullify(e.target.mapPointTextSize.value, preData.projectMapPointTextSize),
                        mapPointTextOffsetX: isCreation ? v_mapPointTextOffsetX : ifSameValueThenNullify(v_mapPointTextOffsetX, preData.projectMapPointTextOffsetX),
                        mapPointTextOffsetY: isCreation ? v_mapPointTextOffsetY : ifSameValueThenNullify(v_mapPointTextOffsetY, preData.projectMapPointTextOffsetY),
                        isEnableValueNotify: isCreation ? e.target.isEnableValueNotify.checked : ifSameValueThenNullify(e.target.isEnableValueNotify.checked, preData.projectIsEnableValueNotify),
                        isEnableValueNotifyByLine: isCreation ? e.target.isEnableValueNotifyByLine.checked : ifSameValueThenNullify(e.target.isEnableValueNotifyByLine.checked, preData.projectIsEnableValueNotifyByLine),
                        lineNotifyDestination: isCreation ? e.target.lineNotifyDestination.value : ifSameValueThenNullify(e.target.lineNotifyDestination.value, preData.projectLineNotifyDestination),
                        notifyMessageTemplate: isCreation ? e.target.notifyMessageTemplate.value : ifSameValueThenNullify(e.target.notifyMessageTemplate.value, preData.projectNotifyMessageTemplate),
                        lineNotifyCooldownMinutes: isCreation ? v_lineNotifyCooldownMinutes : ifSameValueThenNullify(v_lineNotifyCooldownMinutes, preData.projectLineNotifyCooldownMinutes)
                    };
                    submitHandler(e, submittedData);
                }}>
                    {!canEdit && (<Row className="mb-3"><Col>非專案管理員，唯讀模式。</Col></Row>)}
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>專案顯示名稱</Form.Label>
                                <Form.Control required={isCreation} name="projectName" type="text" defaultValue={isCreation ? "" : preData?.projectName ?? ''} disabled={!canEdit} />
                            </Form.Group>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>資料庫鍵</Form.Label>
                                <Form.Control required={isCreation} name="databaseName" type="text" defaultValue={isCreation ? "" : preData?.databaseName ?? ''} disabled={!canEdit}
                                    placeholder="此為影響系統運作的重大鍵值"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3">
                            <Form.Group>
                                <Form.Label>專案說明</Form.Label>
                                <Form.Control type="text" name="projectDescription" as="textarea" rows={3} defaultValue={isCreation ? "" : preData?.projectDescription ?? ''} disabled={!canEdit} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Form.Group>
                                <Form.Label>地震事件設定</Form.Label>
                                <Form.Check type="switch" className="form-check">
                                    <Form.Check.Input type="checkbox" id="cb_ListEQ_Enabled" name="listEQ_Enabled" defaultChecked={isCreation ? false : preData?.projectListEQ_Enabled} disabled={!canEdit} />
                                    <Form.Check.Label htmlFor="cb_ListEQ_Enabled" title="允許使用「地震事件」">
                                        允許使用「地震事件」
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                        <Col className="mb-3">
                            <Form.Group>
                                <Form.Label>地震事件檔目錄</Form.Label>
                                <Form.Control ref={refTb_ListEQ_DataFolder} type="text" name="listEQ_DataFolder" defaultValue={isCreation ? "" : preData?.projectListEQ_DataFolder} disabled={!canEdit} />
                            </Form.Group>
                        </Col>
                        <Col className="mb-3">
                            <Form.Group>
                                <Form.Label>深度測量標題</Form.Label>
                                <Form.Control type="text" name="depthSeries_Title" defaultValue={isCreation ? "" : preData?.projectDepthSeries_Title} disabled={!canEdit} />
                            </Form.Group>
                        </Col>
                        {/*<Col md='auto' className="mb-3">*/}
                        {/*    <Form.Group>*/}
                        {/*        <Form.Label>_</Form.Label><br/>*/}
                        {/*        <Button onClick={e => {*/}
                        {/*            e.preventDefault();*/}
                        {/*            if (refTb_ListEQ_DataFolder?.current) {*/}
                        {/*                refTb_ListEQ_DataFolder.current.value = "*";*/}
                        {/*            }*/}
                        {/*        }}>使用預設</Button>*/}
                        {/*    </Form.Group>*/}
                        {/*</Col>*/}
                    </Row>
                    <Row>
                        <Col md={12} className="mb-3">
                            <Form.Group>
                                <Form.Label>即時簡表設定</Form.Label>
                                <Form.Check type="switch" className="form-check">
                                    <Form.Check.Input type="checkbox" id="cb_StatusTable_Enabled" name="statusTable_Enabled" defaultChecked={isCreation ? false : preData?.projectStatusTable_Enabled} disabled={!canEdit} />
                                    <Form.Check.Label htmlFor="cb_StatusTable_Enabled" title="允許使用「即時簡表」">
                                        允許使用「即時簡表」
                                    </Form.Check.Label>
                                </Form.Check>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {/*kind = {preData?.projectStatusDisplayKind ?? 'null'}*/}
                        {/*isNumber= {!isNaN(preData?.projectStatusDisplayKind) ? 'yes' : 'no'}*/}
                        <Col md={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>燈號顯示方式</Form.Label>
                                <Form.Select
                                    aria-label="Status Display Kind"
                                    name="statusDisplayKind"
                                    value={projDisplayKind}
                                    onChange={e => setProjDisplayKind(e.target.value)}
                                    disabled={!canEdit}
                                >
                                    <option value="0">預設</option>
                                    <option value="1">GeoNow 樣式</option>
                                    <option value="7">經典燈號 (XS)</option>
                                    <option value="8">經典燈號 (S)</option>
                                    <option value="2">經典燈號 (M)</option>
                                    <option value="9">經典燈號 (L)</option>
                                    <option value="10">經典燈號 (XL)</option>
                                    <option value="3">繪文字: 人臉01</option>
                                    <option value="4">繪文字: 人臉02</option>
                                    <option value="5">繪文字: 符號01</option>
                                    <option value="6">繪文字: 符號02</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        {/* ...其餘的 <Col> 組件 */}
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <Form.Label>地圖格式自訂</Form.Label>
                        </Col>
                        <Col>
                            <Form.Label>邊框色彩編碼</Form.Label>
                            <Form.Control type="text" name="mapPointTextBorderColor" defaultValue={isCreation ? "" : preData?.projectMapPointTextBorderColor} disabled={!canEdit} />
                        </Col>
                        <Col>
                            <Form.Label>文字色彩編碼</Form.Label>
                            <Form.Control type="text" name="mapPointTextColor" defaultValue={isCreation ? "" : preData?.projectMapPointTextColor} disabled={!canEdit} />
                        </Col>
                        <Col>
                            <Form.Label>文字尺寸編碼</Form.Label>
                            <Form.Control type="text" name="mapPointTextSize" defaultValue={isCreation ? "" : preData?.projectMapPointTextSize} disabled={!canEdit} />
                        </Col>
                        <Col>
                            <Form.Label>X偏移量</Form.Label>
                            <Form.Control type="text" name="mapPointTextOffsetX" defaultValue={isCreation ? "" : preData?.projectMapPointTextOffsetX} disabled={!canEdit} />
                        </Col>
                        <Col>
                            <Form.Label>Y偏移量</Form.Label>
                            <Form.Control type="text" name="mapPointTextOffsetY" defaultValue={isCreation ? "" : preData?.projectMapPointTextOffsetY} disabled={!canEdit} />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12}>
                            <Form.Label>首頁圖片</Form.Label>
                        </Col>
                        <Col>
                            <Uploady multiple={false}
                                clearPendingOnAdd={true}
                                debug autoUpload={true} destination={{
                                    url: geoNow.api.project.getUri_uploadAndSetDescriptionImage(preData?.projectId),
                                    headers: {
                                        'Authorization': 'Bearer ' + geoNow.getToken()
                                    }
                                }}>
                                {/*{image && !uploading && (*/}
                                {/*    <div>*/}
                                {/*        <img src={image.dataURL} alt="預覽圖片" />*/}
                                {/*        <button onClick={() => image.upload()}>上傳圖片</button>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                <UploadButton className={"btn btn-secondary"} type={"button"} onClick={e => {
                                    e.preventDefault();
                                    return;
                                }}>選擇圖片...</UploadButton>
                                {/*<UploadPreview onUpdate={onPreviewUpdate} />*/}
                                {/*<UploadySingleImagePreview/>*/}
                            </Uploady>
                            <Alert variant="warning" className="mt-2">
                                選擇圖片完即刻上傳，無須點選儲存，即刻生效。
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Form.Label>資料欄位自訂</Form.Label>
                            <DndSortComponent onChange={handleDataListChange} />
                            <Alert variant="info">
                                上下拖曳以調整顯示順序 (由上而下)，勾選以顯示，取消勾選以隱藏。儲存後生效。
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="mt-3" xs={12}>
                            <Form.Label>通知告警</Form.Label>
                            <Alert variant="info">
                                通知告警僅開放比專案管理員更高階的 SuperUser 設定，通常為捷儀人員。
                            </Alert>
                        </Col>
                        <Form.Group>
                            <Form.Check type="switch" className="form-check">
                                <Form.Check.Input type="checkbox" id="cb_ValueNotify_Enabled" name="isEnableValueNotify" defaultChecked={isCreation ? false : preData?.projectIsEnableValueNotify} disabled={!canEdit || !isSuperUser} />
                                <Form.Check.Label htmlFor="cb_ValueNotify_Enabled" title="允許通知告警">
                                    允許通知告警
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check type="switch" className="form-check">
                                <Form.Check.Input type="checkbox" id="cb_ValueNotifyByLine_Enabled" name="isEnableValueNotifyByLine" defaultChecked={isCreation ? false : preData?.projectIsEnableValueNotifyByLine} disabled={!canEdit || !isSuperUser} />
                                <Form.Check.Label htmlFor="cb_ValueNotifyByLine_Enabled" title="允許通知告警">
                                    啟動 Line 通知告警
                                </Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                        <Col>
                            <Form.Label>Line 通知目標 ID</Form.Label>
                            <Form.Control type="text" name="lineNotifyDestination" defaultValue={isCreation ? "" : preData?.projectLineNotifyDestination} disabled={!canEdit || !isSuperUser} />
                        </Col>
                        <Col>
                            <Form.Label>Line 通知間隔分鐘</Form.Label>
                            <Form.Control type="text" name="lineNotifyCooldownMinutes" defaultValue={isCreation ? "" : preData?.projectLineNotifyCooldownMinutes} disabled={!canEdit || !isSuperUser} />
                        </Col>
                        <Col md={12}>
                            <Form.Label>告警訊息自訂範本</Form.Label>
                            <Form.Control as="textarea" rows="3" type="text" name="notifyMessageTemplate" defaultValue={isCreation ? "" : preData?.projectNotifyMessageTemplate} disabled={!canEdit || !isSuperUser} />
                        </Col>
                    </Row>

                    {/*<Row>*/}
                    {/*    {isCreation && (*/}
                    {/*        <>*/}
                    {/*            創建模式*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*    {!isCreation && (*/}
                    {/*        <>*/}
                    {/*            編輯模式*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</Row>*/}
                    <div className="mt-3">
                        {canEdit && (
                            <Button variant="primary" type="submit">
                                {isCreation ? "新增" : "更改"}
                            </Button>
                        )
                        }
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};
