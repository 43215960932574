//import { act } from "@testing-library/react";
/**
 * @typedef {Object} Project
 * @property {string} id - The project id.
 * @property {string} projectName - The name of the project.
 * @property {string} databaseName - The name of the database.
 * @property {Date} createdUtcTime - The date the project was created.
 * @property {string} createdByUser - The id of the user who created the project.
 * @property {string} updatedByUser - The id of the user who last updated the project.
 * @property {boolean} disabled - Whether the project is disabled.
 * @property {string} description - A description of the project.
 * @property {boolean} statusTable_Enabled - Status table enabled flag.
 * @property {boolean} listEQ_Enabled - ListEQ enabled flag.
 * @property {string} listEQ_DataFolder - The data folder of ListEQ.
 * @property {string} depthSeries_Title - The title of the depth series.
 * @property {?number} statusDisplayKind - Status light kind.
 * @property {boolean} isEnableValueNotify - Enable value notify flag.
 * @property {boolean} isEnableValueNotifyByLine - Enable value notify by line flag.
 * @property {string} lineNotifyDestination - Line notify destination.
 * @property {string} notifyMessageTemplate - Notify message template.
 * @property {?number} lineNotifyCooldownMinutes - Line notify cooldown minutes.
 */

/**
 * @typedef {Object} State
 * @property {Project[]} joinedProjects - The list of projects the user has joined.
 * @property {string} currentProjectId - The id of the currently selected project.
 */

/**
 * The project reducer.
 * @param {State} state - The current state.
 * @param {Object} action - The dispatched action.
 * @returns {State} The new state.
 */
const projectReducer = (state = {
    joinedProjects: [],
    currentProjectId: null
    //id: null,
    //projectName: null,
    //databaseName: null,
    //createdUtcTime: null,
    //createdByUser: null,
    //updatedByUser: null,
    //disabled: false,
    //description: null
}, action) => {
    let p = action.payload;
    switch (action.type) {
        case 'SET_JOINED_PROJECTS':
            return {
                ...state, //copying the original state
                // joinedProjects: [...state.joinedProjects, pSetter ]
                joinedProjects: p
            };
        case 'SET_CURRENT_PROJECT_ID':
            return {
                ...state,
                currentProjectId: p
            };
        case 'UPDATE_PROJECT_BY_ID_FROM_JOINED_PROJECTS':
            console.log("UPDATE_PROJECT_BY_ID_FROM_JOINED_PROJECTS");
            let newJoinedProjects = state.joinedProjects.map(proj => {
                if (proj.projectId === p.projectId) {
                    return {
                        ...proj,
                        ...p.newProjectInfo
                    };
                }
                return proj;
            });
            return {
                ...state,
                joinedProjects: newJoinedProjects,
                    
            };
        //case 'SET_TIME_HISTORY_QUERY_OPTIONS':
        //    return {
        //        ...state,
        //        timeHistoryQueryOptions: p
        //    }
        default:
            return state;
    }
};

export default projectReducer;