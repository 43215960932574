import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faCartArrowDown, faChartPie, faChevronDown, faClipboard, faCommentDots, faFileAlt, faPlus, faRocket, faStore } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, Card, Tab, Tabs, Nav, Form, Alert } from '@themesberg/react-bootstrap';
import { ChoosePhotoWidget, ProfileCardWidget } from "../components/Widgets";
import { UserInfoForm } from "../components/UserInfoForm";
import { ProjectInfoForm } from "../components/ProjectInfoForm";

import ReactSelect from 'react-select';

import { DscForm } from "../components/DscForm";

import {
    ReactGrid as Grid, Column as GridColumn, Row as GridRow,
    CellChange as GridCellChange, TextCell as GridTextCell,
    Id as GridId, MenuOption as GridMenuOption, SelectionMode as GridSelectionMode,
} from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";

import geoNow from '../utils/geonow';
import { useSelector, useDispatch } from 'react-redux';
import { doUpdateUser, doUpdateProjectByIdFromJoinedProjects, doSetJoinedProjects, doUpdateUserPv } from './../actions';

import { subscribe } from 'redux-subscriber';

//import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import Swal from 'sweetalert2';
import { InsEx } from "../components/InsExForm";
import { ProjectKmlForm } from "../components/ProjectKmlForm";



export default () => {

    const dispatch = useDispatch();
    const dispatchUpdateUser = (user) => dispatch(doUpdateUser(user));
    const dispatchUpdateProject = (projectId, newProjectInfo) => dispatch(doUpdateProjectByIdFromJoinedProjects(projectId, newProjectInfo));
    const dispatchSetJoinedProjects = (joinedProjects) => dispatch(doSetJoinedProjects(joinedProjects));
    const dispatchUpdateUserPv = (userId, state) => dispatch(doUpdateUserPv(userId, state));

    const [personalFormData, setGeneralFormData] = React.useState(null);
    const [projectInfoFormData, setProjectInfoFormData] = React.useState(null);

    const [parabaseItems, setParabaseItems] = React.useState([]);
    const [dscItems, setDscItems] = React.useState([]);

    const [instrumentExtends, setInstrumentExtends] = React.useState([]);
    const [isEditingInsExItems, setIsEditingInsExItems] = React.useState(false);

    const [gridCols_InsExItems, setGridCols_InsExItems] = React.useState([]);
    const [gridRows_InsExItems, setGridRows_InsExItems] = React.useState([]);

    //allAssignableUsers
    const [allAssignableUsers, setAllAssignableUsers] = React.useState([]);
    const [activeAssignUser, setActiveAssignUser] = React.useState(null);
    const [activeAssignUserProjects, setActiveAssignUserProjects] = React.useState([]);
    const [activeAssignUserDefaultProject, setActiveAssignUserDefaultProject] = React.useState(null);
    const [activeAssignUserDefaultProjectAdmin, setActiveAssignUserDefaultProjectAdmin] = React.useState(false);
    const [allAssignableProjects, setAllAssignableProjects] = React.useState([]);
    // const refSelectActiveAssignUserProjects = React.useRef(null);

    /**
 * @typedef {Object} RefType
 * @property {Object} current
 * @property {() => void} current.methodOne
 * @property {() => void} current.methodTwo
 */

    const gridInsExItems = useRef(null);

    const DEV_ENABLE_INSEX_GRID = false;

    let getCols_InstrumentExtends = () => {
        if (!instrumentExtends || instrumentExtends.length === 0) {
            return;
        }
        let cols = [];
        Object.keys(instrumentExtends[0]).forEach(key => {
            let newCol = {
                columnId: key,
                resizable: true
            };
            if (key === "pointIdx") {
                newCol = {
                    ...newCol,
                    width: 60,
                };
            } else if (key === "lowerScale" || key === "upperScale") {
                newCol = {
                    ...newCol,
                    width: 60,
                };
            } else if (key === "hidden") {
                newCol = {
                    ...newCol,
                    width: 30,
                };
            } else if (key === "longtitude" || key === "latitude") {
                newCol = {
                    ...newCol,
                    width: 60,
                };
            } else if (key === "height") {
                newCol = {
                    ...newCol,
                    width: 40,
                };
            } else if (key === "depthSeriesConfigId") {
                newCol = {
                    ...newCol,
                    width: 340,
                };
            } else if (key === "id" || key === "projectId") {
                newCol = {
                    ...newCol,
                    width: 10,
                };
            }
            cols.push(newCol);
        });
        return cols;
    }

    let getRows_InstrumentExtends = () => {
        if (!DEV_ENABLE_INSEX_GRID) {
            return;
        }

        if (!instrumentExtends || instrumentExtends.length === 0) {
            return;
        }
        let rows = [];
        rows.push({
            rowId: "header",
            cells: Object.keys(instrumentExtends[0]).map((key, index) => {

                let label = key;

                if (key === "pointIdx") {
                    label = "點位#";
                } else if (key === "lowerScale") {
                    label = "下限";
                } else if (key === "upperScale") {
                    label = "上限";
                } else if (key === "hidden") {
                    label = "隱藏";
                } else if (key === "longtitude") {
                    label = "經度";
                } else if (key === "latitude") {
                    label = "緯度";
                } else if (key === "height") {
                    label = "高度";
                } else if (key === "depthSeriesConfigId") {
                    label = "深度串列 GUID";
                } else if (key === "id" || key === "projectId") {
                    label = "";
                } else if (key === "nickname") {
                    label = "標籤";
                } else if (key === "statusStaticNote") {
                    label = "鎖死狀態備註";
                }

                return {
                    type: "header",
                    text: label,
                    //style: {
                    //    backgroundColor: "white",
                    //    color: "black",
                    //    fontWeight: "bold",
                    //    textAlign: "left",
                    //    padding: "5px",
                    //    border: "1px solid black",
                    //    borderRadius: "5px",
                    //    width: "100px",
                    //    height: "30px"
                    //}
                }
            })
        });
        for (let i = 0; i < instrumentExtends.length; i++) {
            let itemCells = [];
            Object.entries(instrumentExtends[i]).forEach(([key, value]) => {
                let temp = {
                    type: "text",
                    text: value.toString()
                };
                if (key === "id" || key === "projectId") {
                    temp = {
                        ...temp,
                        nonEditable: true
                    }
                }
                else if (key === "hidden" || key === "disabled") {
                    temp = {
                        ...temp,
                        type: "checkbox",
                        checked: value == true,
                        //checkedText: "true",
                        //uncheckedText: "false"
                    }
                } else if (key === "pointIdx" || key === "lowerScale" || key === "upperScale" || key === "longtitude" || key === "latitude" || key === "height") {
                    temp = {
                        ...temp,
                        type: "number",
                        value: value,
                    }
                }
                itemCells.push(temp);
            });

            let newRow = {
                rowId: i,
                cells: itemCells
            };
            rows.push(newRow);
        }
        return rows;
    };

    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const rdx_pageview = useSelector(state => state.pageview);

    const isSuperUser = rdx_user.isSuperUser;
    const token = rdx_user.token;

    const [mainTabKey, setMainTabKey] = React.useState("profile");

    const [selectedProject, setSelectedProject] = React.useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );

    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        setSelectedProject(proj);
    });

    React.useEffect(() => {
        setGeneralFormData({
            firstName: rdx_user?.firstName ?? '',
            lastName: rdx_user?.lastName ?? '',
            username: rdx_user?.username ?? '',
            isLastNameFirst: rdx_user?.isLastNameFirst ?? false
        });

        async function doFetchAllUsers() {
            let res = await geoNow.api.user.listAll(token);
            if (res.status === 1) {
                let users = res.message;
                setAllAssignableUsers(users);
            }
        }
        doFetchAllUsers();
        //async function doFetchAllProjects() {
        //    let res = await geoNow.api.project.listAll(token);
        //    if (res.status === 1) {
        //        let projects = res.message;
        //        setAllAssignableProjects(projects);
        //    }
        //}
        //doFetchAllProjects();
        setAllAssignableProjects(rdx_project.joinedProjects); // SuperUser's projects = all projects (uip)

        if (rdx_pageview[rdx_user.id]) {
            let v = rdx_pageview[rdx_user.id]?.settings?.mainTabKey;
            if (v && v !== '') {
                setMainTabKey(v);
            }
        }
    }, []);

    React.useEffect(() => {
        if (!mainTabKey) {
            return;
        }
        

        //if (!rdx_pageview) {
        //    dispatchUpdateUserPv(rdx_user.id, {}
        //    );
        //}

        if (!rdx_pageview[rdx_user.id]) {
            debugger;
            dispatchUpdateUserPv(rdx_user.id, {
                settings: {
                    mainTabKey: "profile"
                }
            });
        } else {
            dispatchUpdateUserPv(rdx_user.id, {
                settings: {
                    mainTabKey: mainTabKey
                }
            });
        }
    }, [mainTabKey]);

    async function fetchInstrumentExtends() {
        let response = await geoNow.api.instrumentExtends.listByProjectId(token, selectedProject.projectId, null, err => {
            console.log("!");
        });
        if (response.status === 1) {
            let items = response.message;
            setInstrumentExtends(items);
        }
    }
    async function fetchDscItems() {
        let response = await geoNow.api.depthSeriesConfigs.listByProjectId(token, selectedProject.projectId, err => {
            console.log("!");
        });
        if (response.status === 1) {
            let items = response.message;
            setDscItems(items);
        }
    }

    async function fetchParabaseItems() {
        let response = await geoNow.api.siteData.getParabase(token, selectedProject.projectId, err => {
            console.log("!");
        });
        if (response.status === 1) {
            /**@type Array<Object> */
            let items = response.message;
            let map = new Map(items.map(item => [item.pointIdx, item]));
            setParabaseItems(map);
        }
    }



    React.useEffect(() => {
        setProjectInfoFormData(selectedProject);

        if (isEditingInsExItems) {
            fetchInstrumentExtends();
        }

        /**@type Promise[] */
        let promises = [];
        let promisePbItems = geoNow.api.siteData.getParabase(token, selectedProject.projectId, err => {
            console.log(JSON.stringify(err));
        });
        promises.push(promisePbItems);
        let promiseDscItems = geoNow.api.depthSeriesConfigs.listByProjectId(token, selectedProject.projectId, err => {
            console.log(JSON.stringify(err));
        });
        promises.push(promiseDscItems);
        let promiseInsExItems = geoNow.api.instrumentExtends.listByProjectId(token, selectedProject.projectId, null, err => {
            console.log(JSON.stringify(err));
        });
        promises.push(promiseInsExItems);
        Promise.all(promises).then(([resPb, resDsc, resInsEx]) => {
            if (resPb.status === 1) {
                let items = resPb.message;
                let map = new Map(items.map(item => [item.pointIdx, item]));
                setParabaseItems(map);
            }
            if (resDsc.status === 1) {
                let items = resDsc.message;
                setDscItems(items);
            }
            if (resInsEx.status === 1) {
                let items = resInsEx.message;
                setInstrumentExtends(items);
            }
        });

        //fetchDscItems();
        //fetchInstrumentExtends();
        //fetchParabaseItems();

    }, [selectedProject]);

    React.useEffect(() => {
        // update default project to whose prop 'default' is true, 
        // or the first project if no such project
        if (activeAssignUserProjects.length > 0) {
            let defaultProject = activeAssignUserProjects.find(project => project.isProjectDefault);
            if (!defaultProject) {
                defaultProject = activeAssignUserProjects[0];
            }
            setActiveAssignUserDefaultProject(defaultProject);
        } else {
            setActiveAssignUserDefaultProject(null);
        }

    }, [activeAssignUserProjects]);

    if (DEV_ENABLE_INSEX_GRID) {
        React.useState(() => {
            if (isEditingInsExItems) {
                fetchInstrumentExtends();
            }
        }, [isEditingInsExItems]);
    }


    React.useEffect(() => {
        setGridCols_InsExItems(getCols_InstrumentExtends());
        setGridRows_InsExItems(getRows_InstrumentExtends());

        if (gridInsExItems?.current) {
            /**@type {ReactGrid} */
            let grid = gridInsExItems?.current;
            grid.forceUpdate(null);
        }
    }, [instrumentExtends]);


    const gridInsExItems_HandleColumnResize = (ci, width) => {
        setGridCols_InsExItems((prevColumns) => {
            const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
            const resizedColumn = prevColumns[columnIndex];
            const updatedColumn = { ...resizedColumn, width };
            prevColumns[columnIndex] = updatedColumn;
            return [...prevColumns];
        });
    }

    function gridInsExItems_handleChanges(changes) {
        setInstrumentExtends((prevItems) => gridInsExItems_applyChanges(changes, prevItems));
    }

    /**
     * 
     * @param {GridCellChange<GridTextCell>[]} changes
     * @param {Object} prevInsExItems
     */
    function gridInsExItems_applyChanges(changes, prevInsExItems) {
        changes.forEach((change) => {

            const rowIndex = change.rowId;
            const fieldName = change.columnId;

            if (change.type === "number") {
                prevInsExItems[rowIndex][fieldName] = change.newCell.value;
                return;
            } else if (change.type === "checkbox") {
                prevInsExItems[rowIndex][fieldName] = change.newCell.checked;
                return;
            }


            prevInsExItems[rowIndex][fieldName] = change.newCell.text;
        });
        return [...prevInsExItems];
    };

    /**
     * 
     * @param {GridId[]} selectedRowIds
     * @param {GridId[]} selectedColIds
     * @param {GridSelectionMode} selectionMode
     * @param {GridMenuOption[]} menuOptions
     * @returns {GridMenuOption[]}
     */
    function gridInsExItems_handleContextMenu(selectedRowIds, selectedColIds, selectionMode, menuOptions) {
        if (selectionMode === "row") {
            menuOptions = [
                ...menuOptions,
                {
                    id: "removeInsExItem",
                    label: "刪除點位進階設置",
                    handler: () => {
                        setInstrumentExtends(prevInsExItems => {
                            return [...prevInsExItems.filter((item, idx) => !selectedRowIds.includes(idx))]
                        });
                    }
                }
            ];
        }
        return menuOptions;
    }

    return (
        <>
            <Row>
                <Col>
                    <Tab.Container activeKey={mainTabKey} onSelect={k => setMainTabKey(k)}>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Nav fill variant="pills" className="flex-column flex-sm-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="profile">個人資料</Nav.Link>
                                    </Nav.Item>
                                    {
                                        isSuperUser && (
                                            <Nav.Item>
                                                <Nav.Link eventKey="super">超級管理員</Nav.Link>
                                            </Nav.Item>
                                        )
                                    }
                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <Nav fill variant="pills" className="flex-column flex-sm-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="project">專案資料</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="kml">專案KML</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="dsc">深度串列</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="insex">進階點位</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>
                        {/*<Nav fill variant="pills" className="flex-column flex-sm-row">*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link eventKey="profile">個人資料</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link eventKey="project">專案資料</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link eventKey="kml">專案KML</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link eventKey="dsc">深度串列</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*    <Nav.Item>*/}
                        {/*        <Nav.Link eventKey="insex">進階點位</Nav.Link>*/}
                        {/*    </Nav.Item>*/}
                        {/*    {*/}
                        {/*        isSuperUser && (*/}
                        {/*            <Nav.Item>*/}
                        {/*                <Nav.Link eventKey="super">超級管理員</Nav.Link>*/}
                        {/*            </Nav.Item>*/}
                        {/*        )*/}
                        {/*    }*/}
                        {/*</Nav>*/}
                        <Tab.Content>
                            {
                                isSuperUser && (
                                    <Tab.Pane eventKey="super">
                                        <Row>
                                            <Col xs={12}>
                                                <Card border="light" className="bg-white shadow-sm mb-4">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col xs={12}>
                                                                <h5 className="mb-4">指派使用者至專案</h5>
                                                            </Col>
                                                            <Col lg={4} xs={12} className='mb-1'>
                                                                <Form.Group>
                                                                    <Form.Label>使用者</Form.Label>
                                                                    <ReactSelect
                                                                        options={allAssignableUsers}
                                                                        getOptionLabel={option => `${option.username} (${option.firstName} ${option.lastName})`}
                                                                        getOptionValue={option => option.id}
                                                                        value={activeAssignUser}
                                                                        onChange={async selected => {
                                                                            // console.log("selectedUser: " + selected);
                                                                            let uips = await geoNow.api.project.getJoinedProjects(token, selected.id);
                                                                            if (uips.status === 1) {
                                                                                setActiveAssignUserProjects(uips.message);
                                                                                // init a list of projects from allAssignableProjects that the user is joined
                                                                                //const projectList = uips.message.map(uip => {
                                                                                //    return allAssignableProjects.find(project => project.id === uip.projectId)
                                                                                //});
                                                                                //setActiveAssignUserProjects(projectList);

                                                                                // get default project
                                                                                let def_proj = uips.message.find(uip => uip.isProjectDefault);
                                                                                if (def_proj) {
                                                                                    setActiveAssignUserDefaultProjectAdmin(def_proj.isProjectAdmin);
                                                                                } else {
                                                                                    setActiveAssignUserDefaultProjectAdmin(false);
                                                                                }
                                                                            }
                                                                            setActiveAssignUser(selected);

                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={8} xs={12} className='mb-1'>
                                                                <Form.Group>
                                                                    <Form.Label>可用專案</Form.Label>
                                                                    <ReactSelect
                                                                        isMulti={true}
                                                                        closeMenuOnSelect={false}
                                                                        options={allAssignableProjects}
                                                                        getOptionLabel={option => option.projectName}
                                                                        getOptionValue={option => option.projectId}
                                                                        isDisabled={!activeAssignUser || activeAssignUser === null || activeAssignUser.isSuperUser}
                                                                        value={activeAssignUserProjects}
                                                                        onChange={selectedOptions => {
                                                                            //console.log("nowUips:");
                                                                            //console.log(selected);
                                                                            //// remove duplicates from selectedOptions
                                                                            //const newSelectedOptions = selectedOptions.filter(
                                                                            //    (selectedOption, index) =>
                                                                            //        index === selectedOptions.findIndex(option => option.id === selectedOption.id)
                                                                            //);
                                                                            let newSelectedOptions = selectedOptions;
                                                                            console.log(newSelectedOptions);
                                                                            setActiveAssignUserProjects(newSelectedOptions);

                                                                            //console.log("rdx_joined:");
                                                                            //console.log(rdx_project.joinedProjects);
                                                                        }} />
                                                                </Form.Group>
                                                                
                                                            </Col>
                                                            <Col xs={12} className='mb-1'>
                                                                <Form.Group>
                                                                    <Form.Label>預設專案</Form.Label>
                                                                    <ReactSelect
                                                                        isMulti={false}
                                                                        closeMenuOnSelect={true}
                                                                        options={activeAssignUserProjects}
                                                                        getOptionLabel={option => option.projectName}
                                                                        getOptionValue={option => option.projectId}
                                                                        value={activeAssignUserDefaultProject}
                                                                        isDisabled={activeAssignUserProjects.length === 0 || !activeAssignUserProjects || !activeAssignUser || activeAssignUser.isSuperUser}
                                                                        onChange={selected => {
                                                                            setActiveAssignUserDefaultProject(selected);
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col xs={12}>
                                                                <Form.Group>
                                                                    <Form.Label>使用者權限</Form.Label>
                                                                    <Form.Check type="switch" className="form-check">
                                                                        <Form.Check.Input
                                                                            type="checkbox" id="cb_supanel_uip_asprojectadmin" name="supanel_uip_asprojectadmin"
                                                                            checked={activeAssignUserDefaultProjectAdmin}
                                                                            onChange={(e) => { setActiveAssignUserDefaultProjectAdmin(e.target.checked) }}
                                                                            disabled={!activeAssignUser || activeAssignUser === null || activeAssignUser.isSuperUser}
                                                                            />
                                                                        <Form.Check.Label htmlFor="cb_supanel_uip_asprojectadmin" title="將該使用者設為專案管理員">
                                                                            管理專案 (將允許變更參數)
                                                                        </Form.Check.Label>
                                                                    </Form.Check>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='mt-3'>
                                                                <Button variant="primary" disabled={!activeAssignUser || activeAssignUser.isSuperUser} onClick={async e => {
                                                                    e.preventDefault();
                                                                    Swal.fire({
                                                                        title: '關聯變更',
                                                                        text: '確定變更該使用者與專案之關聯?',
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#d33',
                                                                        confirmButtonText: '確定變更',
                                                                        cancelButtonText: '取消'
                                                                    }).then(async (result) => {

                                                                        if (!result.isConfirmed) {
                                                                            //Swal.fire(
                                                                            //    '關聯變更',
                                                                            //    '已放棄變更。',
                                                                            //    'info'
                                                                            //);
                                                                            return;
                                                                        }

                                                                        // replace activeAssignUserProjects' user id with the selected user's id
                                                                        activeAssignUserProjects.forEach(uip => {
                                                                            uip.userId = activeAssignUser.id;
                                                                            if (uip.projectId == activeAssignUserDefaultProject.projectId) {
                                                                                uip.isProjectDefault = true;
                                                                            } else {
                                                                                uip.isProjectDefault = false;
                                                                            }
                                                                            // set all project admin status based on the default project, cus this is more intuitive
                                                                            uip.isProjectAdmin = activeAssignUserDefaultProjectAdmin;
                                                                        });
                                                                        let res = await geoNow.api.userInProject.smartPatch(token, activeAssignUserProjects);

                                                                        if (res.status === 1) {
                                                                            Swal.fire({
                                                                                title: '成功',
                                                                                text: '已成功更新使用者與專案關聯！',
                                                                                icon: 'success',
                                                                                showConfirmButton: true,
                                                                            }).then(result => {

                                                                            });
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: '失敗',
                                                                                text: res.message,
                                                                                icon: 'error',
                                                                                showConfirmButton: true
                                                                            });
                                                                        }
                                                                    });
                                                                    
                                                                }}>
                                                                    儲存變更
                                                                </Button>
                                                                {
                                                                    activeAssignUser && activeAssignUser.isSuperUser && (
                                                                        <Alert variant="warning" className="mt-3">
                                                                            該使用者為 SuperUser。超級管理員自能夠存取所有專案，故無須變更。
                                                                        </Alert>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>


                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <UserInfoForm isRegister={true} submitHandler={(e, submittedData) => {
                                                    Swal.fire({
                                                        title: '帳號新增',
                                                        text: "確定要新增帳號嗎?",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        //cancelButtonColor: '#3085d6',
                                                        cancelButtonText: '放棄新增',
                                                        confirmButtonText: '確定新增'
                                                    }).then(async (result) => {

                                                        if (!result.isConfirmed) {
                                                            Swal.fire(
                                                                '帳號資料新增',
                                                                '已放棄新增。',
                                                                'info'
                                                            );
                                                            return;
                                                        }

                                                        let postPayload = {
                                                            firstName: submittedData.firstName,
                                                            lastName: submittedData.lastName,
                                                            username: submittedData.username,
                                                            password: submittedData.password
                                                        };
                                                        let postRes = await geoNow.api.user.add(rdx_user.token, postPayload, true);
                                                        if (postRes.status === 1) {
                                                            Swal.fire({
                                                                title: '帳號新增',
                                                                text: `新帳號已建立！\n帳號: {$submittedData.username}\n密碼: {$submittedData.password}`,
                                                                icon: 'success',
                                                            }).then(result => {
                                                                // window.location.reload(false);
                                                            });
                                                        } else if (postRes.status === -1) {
                                                            let detailReasonCode = postRes.detailCode;
                                                            let detailReadonMsg = '';
                                                            if (detailReasonCode === 720) {
                                                                detailReadonMsg = postRes.message
                                                            } else if (detailReasonCode === 721) {
                                                                detailReadonMsg = "帳號不得為空!";
                                                            } else if (detailReasonCode === 722) {
                                                                detailReadonMsg = "重複，帳號已存在!";
                                                            } else if (detailReasonCode === 729) {
                                                                detailReadonMsg = "密碼強度不符合要求!";
                                                            } else {
                                                                detailReadonMsg = postRes.message;
                                                            }
                                                            Swal.fire({
                                                                title: '失敗',
                                                                text: detailReadonMsg,
                                                                icon: 'error',
                                                                showConfirmButton: true,
                                                            });
                                                        }
                                                    });

                                                }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <ProjectInfoForm isCreation={true} isSuperUser={true} submitHandler={(e, submittedData) => {
                                                    Swal.fire({
                                                        title: '專案新增',
                                                        text: "確定要新增專案嗎?",
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        //cancelButtonColor: '#3085d6',
                                                        cancelButtonText: '放棄變更',
                                                        confirmButtonText: '確定送出'
                                                    }).then(async (result) => {


                                                        if (!result.isConfirmed) {
                                                            //Swal.fire(
                                                            //    '專案新增',
                                                            //    '已放棄新增。',
                                                            //    'info'
                                                            //);
                                                            return;
                                                        }

                                                        let postPayload = {
                                                            projectName: submittedData.projectName,
                                                            databaseName: submittedData.databaseName,
                                                            description: submittedData.projectDescription,
                                                            listEQ_Enabled: submittedData.listEQ_Enabled,
                                                            listEQ_DataFolder: submittedData.listEQ_DataFolder,
                                                            statusTable_Enabled: submittedData.statusTable_Enabled,
                                                            depthSeries_Title: submittedData.depthSeries_Title,
                                                            statusDisplayKind: submittedData.statusDisplayKind
                                                        };

                                                        let postRes = await geoNow.api.project.add(rdx_user.token, postPayload);
                                                        if (postRes.status === 1) {
                                                            const added_proj_id = postRes.message;
                                                            const res_project = await geoNow.api.project.getById(rdx_user.token, added_proj_id);
                                                            if (res_project.status === 1) {
                                                                let projectData = res_project.message;
                                                                let uipProject = {
                                                                    projectId: projectData.id,
                                                                    projectName: projectData.projectName,
                                                                    databaseName: projectData.databaseName,
                                                                    projectDisabled: projectData.disabled,
                                                                    projectDescription: projectData.description,
                                                                    projectListEQ_Enabled: projectData.listEQ_Enabled,
                                                                    projectListEQ_DataFolder: projectData.listEQ_DataFolder,
                                                                    projectStatusTable_Enabled: projectData.statusTable_Enabled,
                                                                    projectDepthSeries_Title: projectData.depthSeries_Title,
                                                                    projectStatusDisplayKind: projectData.statusDisplayKind
                                                                };

                                                                const new_j_projs = await geoNow.api.project.getJoinedProjects(rdx_user.token, rdx_user.id);
                                                                if (new_j_projs.status === 1) {
                                                                    dispatchSetJoinedProjects(new_j_projs.message);
                                                                } else {
                                                                    debugger;
                                                                }

                                                                Swal.fire({
                                                                    title: '專案新增',
                                                                    text: '新專案已建立！',
                                                                    icon: 'success',
                                                                    timer: 2000,
                                                                }).then(result => {
                                                                    // window.location.reload(false);
                                                                });

                                                            }


                                                        } else if (postRes.status === -1) {
                                                            Swal.fire({
                                                                title: '失敗',
                                                                text: putRes.message,
                                                                icon: 'error',
                                                                showConfirmButton: true,
                                                            });
                                                        }
                                                    });
                                                }} />

                                            </Col>
                                        </Row>

                                    </Tab.Pane>
                                )
                            }
                            <Tab.Pane eventKey="profile">
                                <Row>
                                    <Col xs={12}>
                                        <UserInfoForm preData={personalFormData} isRegister={false} submitHandler={(e, submittedData) => {
                                            e.preventDefault();
                                            Swal.fire({
                                                title: '帳號資料變更',
                                                text: "確定要修改帳號資料嗎?",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#d33',
                                                //cancelButtonColor: '#3085d6',
                                                cancelButtonText: '放棄變更',
                                                confirmButtonText: '確定送出'
                                            }).then(async (result) => {


                                                if (!result.isConfirmed) {
                                                    //Swal.fire(
                                                    //    '帳號資料變更',
                                                    //    '已放棄變更，帳號資料維持現狀。',
                                                    //    'info'
                                                    //);
                                                    return;
                                                }

                                                let putPayload = {
                                                    firstName: submittedData.firstName,
                                                    lastName: submittedData.lastName,
                                                    username: submittedData.username,
                                                    password: submittedData.password,
                                                    isLastNameFirst: submittedData.isLastNameFirst
                                                };
                                                let putRes = await geoNow.api.user.update(rdx_user.token, rdx_user.id, putPayload);
                                                if (putRes.status === 1) {

                                                    let userMeRes = await geoNow.api.user.getUserMe(token);
                                                    if (userMeRes.status === 1) {

                                                        let userData = userMeRes.message;

                                                        dispatchUpdateUser(userData);

                                                        Swal.fire({
                                                            title: '資料變更',
                                                            text: '個人資料已變更成功！',
                                                            icon: 'success',
                                                            showConfirmButton: true
                                                        });
                                                    }
                                                } else if (putRes.status === -1) {
                                                    Swal.fire({
                                                        title: 'Error',
                                                        text: putRes.message,
                                                        icon: 'error',
                                                        showConfirmButton: true,
                                                    });
                                                }
                                            });
                                        }} />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="project">
                                <Row>
                                    <Col xs={12}>
                                        <ProjectInfoForm isSuperUser={isSuperUser} preData={projectInfoFormData} isCreation={false} submitHandler={(e, submittedData) => {
                                            Swal.fire({
                                                title: '專案資料變更',
                                                text: "確定要修改專案資料嗎?",
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonColor: '#d33',
                                                //cancelButtonColor: '#3085d6',
                                                cancelButtonText: '放棄變更',
                                                confirmButtonText: '確定送出'
                                            }).then(async (result) => {


                                                if (!result.isConfirmed) {
                                                    //Swal.fire(
                                                    //    '專案資料變更',
                                                    //    '已放棄變更，專案資料維持現狀。',
                                                    //    'info'
                                                    //);
                                                    return;
                                                }

                                                let putPayload = {
                                                    projectName: submittedData.projectName,
                                                    databaseName: submittedData.databaseName,
                                                    description: submittedData.projectDescription,
                                                    listEQ_Enabled: submittedData.listEQ_Enabled,
                                                    listEQ_DataFolder: submittedData.listEQ_DataFolder,
                                                    statusTable_Enabled: submittedData.statusTable_Enabled,
                                                    depthSeries_Title: submittedData.depthSeries_Title,
                                                    statusDisplayKind: submittedData.statusDisplayKind,
                                                    customDataFieldOrder: submittedData.customDataFieldOrder,
                                                    mapPointTextSize: submittedData.mapPointTextSize,
                                                    mapPointTextColor: submittedData.mapPointTextColor,
                                                    mapPointTextBorderColor: submittedData.mapPointTextBorderColor,
                                                    mapPointTextOffsetX: submittedData.mapPointTextOffsetX,
                                                    mapPointTextOffsetY: submittedData.mapPointTextOffsetY,
                                                    isEnableValueNotify: submittedData.isEnableValueNotify,
                                                    isEnableValueNotifyByLine: submittedData.isEnableValueNotifyByLine,
                                                    lineNotifyDestination: submittedData.lineNotifyDestination,
                                                    notifyMessageTemplate: submittedData.notifyMessageTemplate,
                                                    lineNotifyCooldownMinutes: submittedData.lineNotifyCooldownMinutes
                                                };
                                                let putRes = await geoNow.api.project.update(rdx_user.token, selectedProject.projectId, putPayload);
                                                if (putRes.status === 1) {

                                                    const res_project = await geoNow.api.project.getById(rdx_user.token, selectedProject.projectId);
                                                    if (res_project.status === 1) {
                                                        let projectData = res_project.message;
                                                        let uipProject = {
                                                            projectId: projectData.id,
                                                            projectName: projectData.projectName,
                                                            databaseName: projectData.databaseName,
                                                            projectDisabled: projectData.disabled,
                                                            projectDescription: projectData.description,
                                                            projectListEQ_Enabled: projectData.listEQ_Enabled,
                                                            projectListEQ_DataFolder: projectData.listEQ_DataFolder,
                                                            projectStatusTable_Enabled: projectData.statusTable_Enabled,
                                                            projectDepthSeries_Title: projectData.depthSeries_Title,
                                                            projectStatusDisplayKind: projectData.statusDisplayKind,
                                                            projectCustomDataFieldOrder: projectData.customDataFieldOrder,
                                                            projectMapPointTextSize: projectData.mapPointTextSize,
                                                            projectMapPointTextColor: projectData.mapPointTextColor,
                                                            projectMapPointTextBorderColor: projectData.mapPointTextBorderColor,
                                                            projectMapPointTextOffsetX: projectData.mapPointTextOffsetX,
                                                            projectMapPointTextOffsetY: projectData.mapPointTextOffsetY,
                                                            projectIsEnableValueNotify: projectData.isEnableValueNotify,
                                                            projectIsEnableValueNotifyByLine: projectData.isEnableValueNotifyByLine,
                                                            projectLineNotifyDestination: projectData.lineNotifyDestination,
                                                            projectNotifyMessageTemplate: projectData.notifyMessageTemplate,
                                                            projectLineNotifyCooldownMinutes: projectData.lineNotifyCooldownMinutes
                                                        };

                                                        dispatchUpdateProject(projectData.id, uipProject);

                                                        Swal.fire({
                                                            title: '資料變更',
                                                            text: '專案資料已變更成功！',
                                                            icon: 'success',
                                                            timer: 2000,
                                                        }).then(result => {
                                                            window.location.reload(false);
                                                        });

                                                    }


                                                } else if (putRes.status === -1) {
                                                    Swal.fire({
                                                        title: 'Error',
                                                        text: putRes.message,
                                                        icon: 'error',
                                                        showConfirmButton: true,
                                                    });
                                                }
                                            });
                                        }} />
                                    </Col>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="dsc">
                                <DscForm preData={dscItems} selectedProject={selectedProject} token={token} onSavedChanges={() => {
                                    fetchDscItems();
                                }} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="insex">
                                <InsEx preData={instrumentExtends} depthSeriesConfigs={dscItems} pbItems={parabaseItems} selectedProject={selectedProject} token={token} onSavedChanges={() => {
                                    fetchInstrumentExtends();
                                }} />
                                {DEV_ENABLE_INSEX_GRID && (
                                    <Row>
                                        <Col>
                                            <Card border="light" className="bg-white shadow-sm mb-4">
                                                <Card.Body>
                                                    <Row>
                                                        <h5 className="mb-4">進階點位</h5>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={12} className="mb-1">
                                                            <Button onClick={e => {
                                                                e.preventDefault();
                                                                setIsEditingInsExItems(!isEditingInsExItems);
                                                            }}>
                                                                {!isEditingInsExItems ? "類試算表編輯 (Beta)" : "離開類試算表"}
                                                            </Button>
                                                            {isEditingInsExItems &&
                                                                <Button variant="danger" className="float-end"
                                                                    onClick={e => {
                                                                        e.preventDefault();

                                                                        console.log(instrumentExtends);



                                                                        async function doTask() {
                                                                            let res = await geoNow.api.instrumentExtends.importToProject(token, selectedProject.projectId, instrumentExtends, err => {

                                                                            });
                                                                            if (res.status === 1) {
                                                                                Swal.fire({
                                                                                    title: '資料變更',
                                                                                    text: '試算表資料已變更成功！',
                                                                                    icon: 'success',
                                                                                    timer: 2000,
                                                                                }).then(result => {
                                                                                    setIsEditingInsExItems(false);
                                                                                });
                                                                            } else if (res.status === -1) {
                                                                                Swal.fire({
                                                                                    title: '資料變更失敗',
                                                                                    text: res.message,
                                                                                    icon: 'error',
                                                                                    showConfirmButton: true,
                                                                                });
                                                                            }
                                                                        }
                                                                        doTask();


                                                                        //if (gridInsExItems?.current) {
                                                                        //    /**@type {Grid} */
                                                                        //    let grid = gridInsExItems?.current;
                                                                        //    grid.;
                                                                        //}
                                                                        //setIsEditingInsExItems(!isEditingInsExItems);
                                                                    }}
                                                                >
                                                                    儲存編輯
                                                                </Button>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {(isEditingInsExItems &&
                                                                instrumentExtends && instrumentExtends.length > 0 &&
                                                                gridCols_InsExItems && gridCols_InsExItems.length > 0 &&
                                                                gridRows_InsExItems && gridRows_InsExItems.length > 0) &&
                                                                <div className="overflow-auto" style={{ height: 400 + 'px' }}>
                                                                    <Grid ref={gridInsExItems} rows={gridRows_InsExItems} columns={gridCols_InsExItems}
                                                                        onColumnResized={gridInsExItems_HandleColumnResize}
                                                                        onCellsChanged={gridInsExItems_handleChanges}
                                                                        onContextMenu={gridInsExItems_handleContextMenu}
                                                                        enableRowSelection
                                                                        enableRangeSelection
                                                                        enableFillHandle
                                                                        stickyTopRows={1}
                                                                        stickyLeftColumns={1}
                                                                    />
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey="kml">
                                {/*<KmlForm selectedProject={selectedProject} token={token} />*/}
                                <ProjectKmlForm projectId={selectedProject.projectId} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </Col>
            </Row>

        </>
    );
};
