
import React, { useState, useRef } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBook, faBoxOpen, faChartPie, faCog, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket,
    faChartLine, faHouseDamage, faDatabase, faGripLinesVertical, faTree, faSync, faHome
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import GeotechSVG from "../assets/img/illustrations/geotechscience.svg";
import GeonowSVG from "../assets/img/icons/geonow.svg";
import GeoNowLogo from "../assets/img/icons/GeoNow_WithText_Dark_260.png";
import GeoNowMiniLogo from "../assets/img/icons/GeoNow_ms150.png";

import { useSelector } from 'react-redux';
import { subscribe } from 'redux-subscriber';
import geoNow from '../utils/geonow';

export default (props = {}) => {
    const rdx_project = useSelector(state => state.project);
    const rdx_user = useSelector(state => state.user);
    const token = rdx_user.token;

    const [selectedProject, setSelectedProject] = React.useState(
        geoNow.stateHelper.project.getJoinedProjectItemByProjectId(rdx_project.currentProjectId, rdx_project.joinedProjects)
    );
    const unsubscribe_currentProjectId = subscribe('project.currentProjectId', state => {
        let id = state.project.currentProjectId;
        let proj = geoNow.stateHelper.project.getJoinedProjectItemByProjectId(id, state.project.joinedProjects);
        setSelectedProject(proj);
    });
    

    const location = useLocation();
    const { pathname } = location;
    const [show, setShow] = useState(false);
    const showClass = show ? "show" : "";

    const onCollapse = () => setShow(!show);

    const CollapsableNavItem = (props) => {
        const { eventKey, title, icon, children = null } = props;
        const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

        return (
            <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
                <Accordion.Item eventKey={eventKey}>
                    <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
                        <span>
                            <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
                            <span className="sidebar-text">{title}</span>
                        </span>
                    </Accordion.Button>
                    <Accordion.Body className="multi-level">
                        <Nav className="flex-column">
                            {children}
                        </Nav>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        );
    };

    const NavItem = (props) => {
        const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary", disabled = false } = props;
        const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
        const navItemClassName = link === pathname ? "active" : "";
        const linkProps = external ? { href: link } : { as: Link, to: link };

        return (
            <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
                <Nav.Link {...linkProps} target={target} className={classNames} disabled={disabled ? true : null}>
                    <span>
                        {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} className={disabled ? "fa-disabled" : ''} /> </span> : null}
                        {image ? <Image src={image} width={20} height={20} className={"sidebar-icon svg-icon" + (disabled ? " fa-disabled" : '')} /> : null}

                        <span className="sidebar-text">{title}</span>
                    </span>
                    {badgeText ? (
                        <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
                    ) : null}
                </Nav.Link>
            </Nav.Item>
        );
    };

    return (
        <>
            <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
                <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
                    <Image src={GeoNowMiniLogo} className="navbar-brand-light" />
                </Navbar.Brand>
                <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
                    <span className="navbar-toggler-icon" />
                </Navbar.Toggle>
            </Navbar>
            <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
                <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
                    <div className="sidebar-inner px-4 pt-3">
                        <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
                            <div className="d-flex align-items-center">
                                <div className="user-avatar lg-avatar me-4">
                                    <Image src={rdx_user.avatarUrlSmall} className="card-img-top rounded-circle border-white" />
                                </div>
                                <div className="d-block">
                                    {rdx_user.isLastNameFirst ? (
                                        <>
                                            <h6>{rdx_user.lastName + " " + rdx_user.firstName}</h6>
                                        </>
                                    ) : (
                                        <>
                                            <h6>{rdx_user.firstName + " " + rdx_user.lastName}</h6>
                                        </>
                                    )}
                                    
                                    <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> 登出
                                    </Button>
                                </div>
                            </div>
                            <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Nav.Link>
                        </div>
                        <Nav className="flex-column pt-3 pt-md-0">
                            {/*<NavItem title="Volt React" link={Routes.Presentation.path} image={ReactHero} />*/}
                            {/*<Image ref="imgLogoRef" src={GeonowSVG} style={{ opacity: 0.1, marginBottom: imgLogoRef?.current?.offsetWidth ?? 0 + 'px' }} />*/}
                            <div>
                                <Image src={GeoNowLogo} style={{ maxWidth: '100%', height: 'auto' }} />
                            </div>
                            
                            <NavItem title="首頁" link={Routes.DashboardOverview.path} icon={faHome} /*image={GeonowSVG}*/ />
                            <NavItem title="資料一覽" link={Routes.DataOverview.path} icon={faDatabase} />
                            <NavItem title="歷時資料" link={Routes.TimeHistory.path} icon={faChartLine} />
                            <NavItem title="深度測量" link={Routes.DepthSeries.path} icon={faGripLinesVertical} />
                            <NavItem title="地震事件" link={Routes.EarthquakeWatcher.path} icon={faHouseDamage} disabled={selectedProject && !selectedProject.projectListEQ_Enabled} />
                            <NavItem title="即時簡表" link={Routes.StatusTable.path} icon={faSync} disabled={selectedProject && !selectedProject.projectStatusTable_Enabled} />
                            {/*{*/}
                            {/*    selectedProject && selectedProject.projectListEQ_Enabled && (*/}
                            {/*        <NavItem title="地震事件" link={Routes.EarthquakeWatcher.path} icon={faChartPie} />*/}
                            {/*    )*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    selectedProject && selectedProject.projectStatusTable_Enabled && (*/}
                            {/*        <NavItem title="即時簡表" link={Routes.StatusTable.path} icon={faChartPie} />*/}
                            {/*    )*/}
                            {/*}*/}
                            
                            
                            


                            {/*<NavItem external title="Messages" link="https://demo.themesberg.com/volt-pro-react/#/messages" target="_blank" badgeText="Pro" icon={faInbox} />*/}
                            {/*<NavItem title="Transactions" icon={faHandHoldingUsd} link={Routes.Transactions.path} />*/}
                            {/*<NavItem title="Settings" icon={faCog} link={Routes.Settings.path} />*/}
                            {/*<NavItem external title="Calendar" link="https://demo.themesberg.com/volt-pro-react/#/calendar" target="_blank" badgeText="Pro" icon={faCalendarAlt} />*/}
                            {/*<NavItem external title="Map" link="https://demo.themesberg.com/volt-pro-react/#/map" target="_blank" badgeText="Pro" icon={faMapPin} />*/}

                            {/*<CollapsableNavItem eventKey="tables/" title="Tables" icon={faTable}>*/}
                            {/*    <NavItem title="Bootstrap Table" link={Routes.BootstrapTables.path} />*/}
                            {/*</CollapsableNavItem>*/}

                            {/*<CollapsableNavItem eventKey="examples/" title="Page Examples" icon={faFileAlt}>*/}
                            {/*    <NavItem title="Sign In" link={Routes.Signin.path} />*/}
                            {/*    <NavItem title="Sign Up" link={Routes.Signup.path} />*/}
                            {/*    <NavItem title="Forgot password" link={Routes.ForgotPassword.path} />*/}
                            {/*    <NavItem title="Reset password" link={Routes.ResetPassword.path} />*/}
                            {/*    <NavItem title="Lock" link={Routes.Lock.path} />*/}
                            {/*    <NavItem title="404 Not Found" link={Routes.NotFound.path} />*/}
                            {/*    <NavItem title="500 Server Error" link={Routes.ServerError.path} />*/}
                            {/*</CollapsableNavItem>*/}

                            {/*<NavItem external title="Plugins" link="https://demo.themesberg.com/volt-pro-react/#/plugins/datatable" target="_blank" badgeText="Pro" icon={faChartPie} />*/}

                            {/*<Dropdown.Divider className="my-3 border-indigo" />*/}

                            {/*<CollapsableNavItem eventKey="documentation/" title="Getting Started" icon={faBook}>*/}
                            {/*    <NavItem title="Overview" link={Routes.DocsOverview.path} />*/}
                            {/*    <NavItem title="Download" link={Routes.DocsDownload.path} />*/}
                            {/*    <NavItem title="Quick Start" link={Routes.DocsQuickStart.path} />*/}
                            {/*    <NavItem title="License" link={Routes.DocsLicense.path} />*/}
                            {/*    <NavItem title="Folder Structure" link={Routes.DocsFolderStructure.path} />*/}
                            {/*    <NavItem title="Build Tools" link={Routes.DocsBuild.path} />*/}
                            {/*    <NavItem title="Changelog" link={Routes.DocsChangelog.path} />*/}
                            {/*</CollapsableNavItem>*/}
                            {/*<CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>*/}
                            {/*    <NavItem title="Accordion" link={Routes.Accordions.path} />*/}
                            {/*    <NavItem title="Alerts" link={Routes.Alerts.path} />*/}
                            {/*    <NavItem title="Badges" link={Routes.Badges.path} />*/}
                            {/*    <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />*/}
                            {/*    <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />*/}
                            {/*    <NavItem title="Buttons" link={Routes.Buttons.path} />*/}
                            {/*    <NavItem title="Forms" link={Routes.Forms.path} />*/}
                            {/*    <NavItem title="Modals" link={Routes.Modals.path} />*/}
                            {/*    <NavItem title="Navbars" link={Routes.Navbars.path} />*/}
                            {/*    <NavItem title="Navs" link={Routes.Navs.path} />*/}
                            {/*    <NavItem title="Pagination" link={Routes.Pagination.path} />*/}
                            {/*    <NavItem title="Popovers" link={Routes.Popovers.path} />*/}
                            {/*    <NavItem title="Progress" link={Routes.Progress.path} />*/}
                            {/*    <NavItem title="Tables" link={Routes.Tables.path} />*/}
                            {/*    <NavItem title="Tabs" link={Routes.Tabs.path} />*/}
                            {/*    <NavItem title="Toasts" link={Routes.Toasts.path} />*/}
                            {/*    <NavItem title="Tooltips" link={Routes.Tooltips.path} />*/}
                            {/*</CollapsableNavItem>*/}
                            {/*<NavItem external title="Themesberg" link="https://themesberg.com" target="_blank" image={ThemesbergLogo} />*/}
                            {/*<Button as={Link} to={Routes.Signin.path} variant="secondary" className="bottom-left-button"><FontAwesomeIcon icon={faRocket} className="me-1" /> 登入</Button>*/}
                        </Nav>
                    </div>
                </SimpleBar>
            </CSSTransition>
        </>
    );
};
